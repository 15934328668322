import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Badge } from 'reactstrap'
import { get, put } from '../utils/httpAgent'
import { Helmet } from 'react-helmet'
import DetailsForm from './details-form'
import UserForm from './user-form'
import PasswordForm from './password-form'
import CheckboxControl from '../components/checkbox-control'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import Alert from '../shared/alert'

class UserSettingsPage extends Component {
  constructor () {
    super()
    this.input = {
      product_categories: {},
      products: {}
    }
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      product_categories: [],
      application: {
        productCategory: [],
        product: []
      },
      promise: {
        productCategory: [],
        product: []
      },
      accessControlApproved: false,
      adminRole: '',
      userRole: '',
      hasNda: false
    }
    this.handleSetPromise = this.handleSetPromise.bind(this)
  }

  componentDidMount () {
    this.fetchProductData()
    this.fetchData()
  }

  fetchProductData () {
    get('/1/admin/product_categories')
      .then(r => {
        this.setState({
          product_categories: r.data
        })
      })
  }

  fetchData () {
    get(`/1/admin/user/${this.props.match.params.uid}`)
      .then(r => {
        if (r.success) {
          this.setState({
            application: r.data.application,
            promise: r.data.promise,
            adminRole: r.data.adminRole,
            userRole: r.data.userRole,
            eulaSignedDate: r.data.eulaSignedDate ? new Date(r.data.eulaSignedDate).toLocaleDateString() : null,
            accessControlApproved: r.data.accessControlApproved
          })
          this.fetchNdaStatus(r.data.email)
        }
      })
  }

  fetchNdaStatus (email = '@') {
    get(`/1/admin/nda_company/email?domain=${email.split('@').pop()}`)
      .then(r => {
        if (r.success) {
          this.setState({
            hasNda: r.hasNda
          })
        }
      })
  }

  handleSetPromise (isProduct, pid) {
    if (isProduct) {
      let product = this.state.promise.product
      if (!product.includes(pid)) {
        product.push(pid)
      } else {
        product = product.filter(pd => pd !== pid)
      }
      this.setState({
        promise: {
          ...this.state.promise,
          product: product
        }
      })
    } else {
      let productCategory = this.state.promise.productCategory
      if (!productCategory.includes(pid)) {
        productCategory.push(pid)
      } else {
        productCategory = productCategory.filter(pc => pc !== pid)
      }
      this.setState({
        promise: {
          ...this.state.promise,
          productCategory: productCategory
        }
      })
    }
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    put(`/1/admin/user/${this.props.match.params.uid}/promise`, {
      productCategories: this.state.promise.productCategory,
      products: this.state.promise.product
    }).then(r => {
      if (r.success === true) {
        this.setState({
          success: true,
          error: '',
          loading: false,
          hasError: {},
          accessControlApproved: r.data.accessControlApproved
        })
      } else {
        const state = {
          success: false,
          error: '',
          loading: false,
          hasError: {},
          help: {}
        }
        for (const key in r.errfor) {
          state.hasError[key] = true
          state.help[key] = r.errfor[key]
        }

        if (r.errors[0] !== undefined) {
          state.error = r.errors[0]
        }
        this.setState(state)
      }
    })
  } // end handleSubmit

  render () {
    const productCategories = []

    if (Array.isArray(this.state.product_categories)) {
      this.state.product_categories.forEach(pc => {
        const isCategoryChecked = this.state.promise.productCategory.filter(p => p === pc._id).length > 0
        const isCategoryHighlight = this.state.application.productCategory.filter(p => p === pc._id).length > 0

        let disabled = this.state.loading

        if (!pc.userRoles.includes(this.state.userRole)) {
          disabled = true
        }

        productCategories.push(
          <h4 key={`h4-${pc._id}`}>
            <CheckboxControl
              ref={(c) => (this.input[pc._id] = c)}
              name={pc._id}
              key={pc._id}
              label={pc.name}
              value={pc._id}
              checked={isCategoryChecked}
              highlight={isCategoryHighlight}
              onChange={(e) => (this.handleSetPromise(false, e.target.value))}
              disabled={disabled}
            />
          </h4>)

        productCategories.push(<hr className='min-height' key={`hr-${pc._id}`} />)

        if (Array.isArray(pc.product)) {
          pc.product.forEach(pd => {
            const isProductChecked = this.state.promise.product.filter(p => p === pd._id).length > 0
            const isProductHighlight = this.state.application.product.filter(p => p === pd._id).length > 0

            if (isCategoryChecked && isProductChecked) {
              this.handleSetPromise(true, pd._id)
            }

            let disabled = this.state.loading

            if (pd.name === 'Camera API' && this.props.adminRole !== 'root') {
              disabled = true
            }

            if (!pc.userRoles.includes(this.state.userRole)) {
              disabled = true
            }

            productCategories.push(
              <CheckboxControl
                ref={(c) => (this.input[pd._id] = c)}
                name={pd._id}
                key={pd._id}
                label={pd.name}
                value={pd._id}
                checked={isProductChecked}
                highlight={isProductHighlight}
                onChange={(e) => (this.handleSetPromise(true, e.target.value))}
                disabled={disabled}
              />)
          })
        }
      }) // End Product Categories for
    }

    return (
      <section className='container'>
        <Helmet>
          <title>{this.props.match.params.username} 帳號設定</title>
        </Helmet>
        <h1 className='page-header'>
          {this.props.match.params.username} 帳號設定
        </h1>
        <div className='row'>
          <div className='col-sm-6'>
            <UserForm
              uid={this.props.match.params.uid}
              onUserRoleChange={(role) => this.setState({ userRole: role })}
            />
            <DetailsForm aid={this.props.match.params.aid} />
            <PasswordForm uid={this.props.match.params.uid} />
          </div>
          <div className='col-sm-6'>
            <form onSubmit={this.handleSubmit.bind(this)}>
              <legend>技術文件審核</legend>
              <p className='mb-0'>(藍字選項為使用者欲申請之項目)</p>
              {!this.state.adminRole && this.state.userRole === 'client' &&
                <div className='d-inline-block mr-2'>{this.state.hasNda ? <Badge color='primary'>已簽署 NDA</Badge> : <Badge color='danger'>未簽署 NDA</Badge>}</div>}
              {!this.state.adminRole &&
                <div className='d-inline-block mr-2'>{this.state.accessControlApproved ? <Badge color='primary'>已通過審核</Badge> : <Badge color='danger'>未通過審核</Badge>}</div>}
              {!this.state.adminRole && this.state.userRole === 'client-gaia' &&
                <div className='d-inline-block'>{this.state.eulaSignedDate ? <Badge color='primary'>已簽署 EULA ({this.state.eulaSignedDate})</Badge> : <Badge color='danger'>未簽署 EULA</Badge>}</div>}
              {this.state.error &&
                <Alert
                  className='mt-3'
                  type='danger'
                  message={this.state.error}
                />}
              {this.state.success &&
                <Alert
                  className='mt-3'
                  type='success'
                  message={this.props.adminRole === 'root' ? '已通過審核。' : '已提交審核。'}
                />}
              {productCategories}
              <ControlGroup hideLabel hideHelp>
                <Button
                  type='submit'
                  inputClasses={{ 'btn-primary mt-3': true }}
                  disabled={this.state.loading || !['root', 'root-sales'].includes(this.props.adminRole)}
                >
                  {this.props.adminRole === 'root' ? '提交並審核通過' : '提交審核'}
                  <Spinner
                    space='left'
                    show={this.state.loading}
                  />
                </Button>
              </ControlGroup>
              {this.props.adminRole !== 'root' && <p>(提交後，需等待系統管理員審核後，客戶方能下載)</p>}
            </form>
          </div>
        </div>
      </section>
    )
  }
}

const mapStateToProps = state => ({
  user: state.index.user,
  adminRole: state.index.adminRole
})

export default connect(mapStateToProps, null)(UserSettingsPage)
